<template>
  <div class="activity-detail-info-card" v-if="data">

    <div class="activity-detail-info-card-header">
      <div class="career-fair-name">{{data.career_fair_name}}</div>
      <div @click="visibleIsShow" class="btn">报名考试</div>
    </div>

    <div class="activity-detail-info-card-content">
      <el-image style="width: 297px; height: 167px" :src="data.des_link" fit="cover" />
      <div class="right">
        <div class="list-box">
          <div class="list-item">主办单位：{{data.valley_name}}</div>
          <!-- <div class="list-item">考试地点：{{data.address}}</div> -->
          <div class="list-item">考生报名时间：{{applyTime}}</div>
          <!-- <div class="list-item">活动时间：{{time}}</div> -->
        </div>
      </div>
    </div>
    <el-dialog
      title="选择报考的岗位"
      :visible.sync="visible"
      width="50%"
      show-close
      :close-on-click-modal="false"
      center
      
    >
      <div class="search-box">
        <el-input
          placeholder="搜索可报考的岗位"
          class="search-input"
          prefix-icon="el-icon-search"
          @change="searched"
          v-model="search_content"
          size="small"
        >
        <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <el-table
        ref="singleTable"
        :data="jobList"
        highlight-current-row
        @current-change="handleCurrentChange"
        style="width: 100%"
        :row-style="{cursor: 'pointer'}"  
      >

        <el-table-column
          type="index"
          width="50">
        </el-table-column>

        <el-table-column
          property="company_name"
          label="用工单位"
        >
        </el-table-column>

        <el-table-column
          property="job_title"
          label="岗位名称"
        >
        </el-table-column>

        <el-table-column
          property="exam_format"
          label="考试形式">
        </el-table-column>

        <el-table-column
          property="exam_job_category"
          label="岗位类别"
        >
        </el-table-column>

        <el-table-column
          property="required_num"
          label="需求人数"
        >
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <router-link class="table-link" :to="toJobDetail+scope.row.job_id">查看详情</router-link>
          </template>
        </el-table-column>
        
      </el-table>

      <el-pagination
        style="margin:30px auto 0;text-align:center;"
        layout="prev, pager, next"
        :total="jobsTotal"
        :page-size="10"
        :hide-on-single-page="true"
        @current-change="pageChange"
      ></el-pagination>      

      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" :disabled="!curJobId" @click="apply">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {toJobDetail} from "constants/routerLink"
import {mapState} from "vuex"
export default {
  name:"ExaminationDetailHeadInfo",
  props:["data","jobList","jobsTotal"],
  data:()=>({
    visible:false,
    curJobId:null,
    search_content:""
  }),
  created(){
    if(!this.isToken){
      this.$message({
        message:"请先登录并同步简历后再报名",
      })
    }
  },
  methods:{
    searched(val) {
      this.search_content = val;
      this.$emit('getJobList',{offset:0,search_content:val})
    },
    close(){
      this.visible = false
      this.curJobId = null
    },
    // setCurrent(row) {
    //   this.$refs.singleTable.setCurrentRow(row);
    // },
    pageChange(page){
      this.$emit('getJobList',{offset:(page-1)*10})
    },
    handleCurrentChange(val) {
      this.curJobId = val.job_id;
    },
    visibleIsShow(){
      this.visible = true
      this.getJobList()
    },
    apply(){
      this.$emit('apply',this.curJobId)
    },
    getJobList(){
      this.$emit('getJobList')
    }
  },
  computed:{
    ...mapState({
      isToken:state=>!!state.token
    }),
    toJobDetail:()=>toJobDetail,
    applyTime(){
      if(this.data.personal_apply_time){
        if(this.data.personal_ddl_time) return `${this.$dayjs(this.data.personal_apply_time).format("YYYY.MM.DD")} - ${this.$dayjs(this.data.personal_ddl_time).format("YYYY.MM.DD")}`
        else return `${this.$dayjs(this.data.apply_time).format("YYYY.MM.DD")} - 待定`
      }else{
        if(this.data.personal_ddl_time) return `${this.$dayjs(this.data.personal_ddl_time).format("YYYY.MM.DD")} 结束`
        else return `待定`
      }
    },
    time(){
      if(this.data.start_time){
        if(this.data.end_time) return `${this.$dayjs(this.data.start_time).format("YYYY.MM.DD")} - ${this.$dayjs(this.data.personal_end_time).format("YYYY.MM.DD")}`
        else return `${this.$dayjs(this.data.start_time).format("YYYY.MM.DD")} - 待定`
      }else{
        if(this.data.end_time) return `${this.$dayjs(this.data.end_time).format("YYYY.MM.DD")} 结束`
        else return `待定`
      }
    }
  },
  watch:{},

}
</script>
<style lang="scss">
@import "constants/style/public";
.activity-detail-info-card{
  font-size: var(--medium,14px);
  color: var(--cardsubTextColor,#666);

  .activity-detail-info-card-header{
    background-color: var(--defaultBg,#fff);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:20px 26px;
    margin-bottom: 15px;
    border: 1px solid var(--borderLineColor,#F4F4F4);

    .career-fair-name{
      font-size: var(--large,16px);
      font-weight: var(--weight-medium,500);
    }
  }
  
  .activity-detail-info-card-content{
    padding: 20px 26px;
    display: flex;
    background-color: var(--defaultBg,#fff);
    border: 1px solid var(--borderLineColor,#F4F4F4);
    width: 100%;
    
    >.right{
      margin-left: 100px;
      height: 100%;
      .list-box{
        padding-top: 10px;
      }
      .list-item{
        height: 100%;
        margin-bottom: 23px;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
      
    }
  }

  .btn{
    width: 100px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F7782D;
    color: var(--defaultColor,#fff);
    cursor: pointer;
  }
  .table-link{
    color: var(--moreColor,#ff9967);
    &:hover{
      text-decoration: underline;
    }
  }
}

</style>