<template>
  <div class="activity-tetail page-container">
    <ExaminationInfoCard @apply="toApplyExamination" :data="data" :jobList="jobList" @getJobList="getJobList" :jobsTotal="jobsTotal" />
    <DetailTabNav :curIndex="curIndex" :list="list" @change="toggle"/>
    <div style="margin-top:15px">
      <div class="activity-introduction" v-if="curIndex===0 && data" v-html="data.description"/>
      <div v-if="curIndex===1">
        <CompanyListWrapper isExam :ajaxApi="'getCareerFairCompanys'" :nums="18" :ids="[data.career_fair_id]"/>
      </div>
      <div v-if="curIndex===2">
        <JobListWrapper isExam cardVersion="new" :ajaxApi="'getCareerFairJobs'" :nums="18" :ids="[data.career_fair_id]"/>
      </div>
    </div>
   
  </div>
</template>
<script>
import { mapState } from "vuex";
import ExaminationInfoCard from "components/examination/detail/examinationDetailInfo"
import CompanyListWrapper from "components/company/companyListWrapper"
import DetailTabNav from "components/detailTabNav"
import JobListWrapper from "components/jobs/jobListWrapper";
import {examinationDetailNavList} from "constants/publicData"
export default {
  name:"CompanyDetail",
  data:()=>({
    data:null,
    curIndex:0,
    filterData:{},
    jobList:[],
    jobsTotal:null
  }),
  computed:{
    ...mapState({
      user_id: (state) => state.user_id,
      isToken: (state) => state.token?true:false,
    }),
    list:()=>examinationDetailNavList, 
  },
  created(){
    this.getData()
  },
  methods:{
    toApplyExamination(job_id){
      if(this.isToken&&this.user_id){
        this.$router.push({name:'examinationApplyForm',params:{career_fair_id:this.data.career_fair_id,job_id}})
      }else{
        this.$message({
          message:"请先进行个人登录",
          type: 'warning'
        })
      }
    },
    toggle(i){
      this.curIndex = i
    },
    getData() {
      this.$axiosRequest({
        name: "getActivityDetail",
        ids:this.$route.params.id,
      })
        .then((res) => {
          if (res.status === 200) {
            this.data = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getJobList(changData){
      let params={
        // offset:0,
        // limit:10,
        search_content:'',
        city:'',
        experiences:'',
        educations:'',
        industriesL:'',
        salary_range:'',
        whether_urgent:'',
        career_fair_id:this.data.career_fair_id
      }
      params = {...params,...changData}
      this.$axiosRequest({
        name: "getExaminationJobs",
        params: params
      })
        .then((res) => {
          if (res.status === 200) {
            this.jobList = res.data.results
            this.jobsTotal = res.data.total
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  watch:{},
  components:{
    ExaminationInfoCard,
    CompanyListWrapper,
    DetailTabNav,
    JobListWrapper
  }

}
</script>
<style lang="scss">
@import "constants/style/public";
.activity-tetail{
  text-align: left;
  padding-bottom: 60px;
  font-size: var(--medium,14px);
  .activity-introduction{
    padding: 10px 26px;
    min-height: 300px;
    background-color: $defaultBg;
    border: 1px solid var(--borderLineColor,#F4F4F4);
    // margin-top: 25px;
    // box-shadow: 0px 0px 18px $shadowColor;
    // border-radius:$borderRadius;

  }
}
</style>